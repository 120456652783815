import React, { useEffect, useState } from 'react';
import { loginBackground } from '../../../../assets';
import { Col, Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { BiUser, BiLockAlt } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import './index.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { auth as authAction, generalAction } from '../../../../stores/actions';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../../hooks';
import { FETCH_AUTH_LOGIN, FETCH_VERIFY_OTP } from '../../../../stores/types/auth';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const general = useSelector((state) => state.generalReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const { isLoading, error, type, auth } = authReducer;

  const [email, setEmail, emailValid, emailTouched, setEmailTouched, EmailError] = useForm(
    general.username || '',
    (x) => x
  );
  const [password, setPassword, passwordValid, passwordTouched, setPasswordTouched, PasswordError] =
    useForm('', (x) => x);
  const [showPassword, setShowPassword] = useState('password');
  const [isValid, setIsValid] = useState(false);
  // const [loginPressed, setLoginPressed] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState();

  const handleShowPassword = () => {
    if (showPassword === 'password') {
      return setShowPassword('text');
    }
    return setShowPassword('password');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailTouched(true);
    setPasswordTouched(true);
    if (!isValid) {
      return false;
    }
    if (process.env.REACT_APP_RECAPTCHA_ENABLE === 'true' && !captchaResponse) return false;
    if (process.env.REACT_APP_RECAPTCHA_ENABLE === 'true' && captchaResponse) return login();
    if (process.env.REACT_APP_RECAPTCHA_ENABLE === 'false') {
      login();
    }
    return;
  };

  const login = (captcha = '123') => {
    if (process.env.REACT_APP_RECAPTCHA_ENABLE === 'true') {
      return dispatch(
        authAction.fetchDataLogin({
          email,
          password,
          captcha: captchaResponse
        })
      );
    }
    return dispatch(
      authAction.fetchDataLogin({
        email,
        password,
        captcha
      })
    );
  };

  const onError = (textError) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: textError
    }).then(() => {
      dispatch(authAction.fetchLoginFail(null));
      dispatch(authAction.fetchResetType());
      if (process.env.REACT_APP_RECAPTCHA_ENABLE === 'true') {
        window.grecaptcha.reset();
      }
      // setLoginPressed(false);
      if (textError === 'These credential is not active.') {
        navigate(`/activation?email=${email}&status=on_process`);
      }
    });
  };

  useEffect(() => {
    document.body.style = '';
    if (type === FETCH_AUTH_LOGIN.SUCCESS || type === FETCH_VERIFY_OTP.SUCCESS) redirect();
    if (type === FETCH_AUTH_LOGIN.FAILED) onError(error);
  }, [error, type, auth]);

  useEffect(() => {
    setIsValid(emailValid && passwordValid);
  }, [email, password]);

  const redirect = () => {
    dispatch(authAction.fetchResetType());
    rememberInput();
    if (!auth?.access_token) return navigate('verify');
    if (auth?.access_token) {
      // eslint-disable-next-line prettier/prettier
      if (!location.pathname.includes('/cms/login')) return (location.href = process.env.REACT_APP_URL_V3);
      if (!auth?.user?.roles.includes('cms-admin')) return onError("You don't have access");
      navigate('/cms');
    }
  };

  const rememberInput = () => {
    if (!rememberMe) return;
    dispatch(generalAction.rememberUsername(email));
  };

  // // eslint-disable-next-line react/prop-types
  // const Section = ({ title, className }) => (
  //   <div className={`d-flex text-muted fw-bold align-items-center gap-2 ${className}`}>
  //     <span className="section-title">{title}</span>
  //     <span className="section-line" />
  //   </div>
  // );

  return (
    <div className="d-flex flex-row row-container-form">
      <Col xxl="6" xl="6" lg="6" className="container-image-bg-login">
        <div
          className="image-cover-fluid"
          style={{ '--img': `url(${loginBackground})`, height: '91.5vh' }}>
          <div
            className="image-overlay"
            style={{ height: '91.5vh', backgroundColor: 'transparent' }}>
            <div className="image-caption">{t('AUTH-LOGIN-004')}</div>
            <div className="image-description mt-4">{t('AUTH-LOGIN-005')}</div>
          </div>
        </div>
      </Col>
      <Col
        className="p-5 col-container-form position-relative"
        xxl="6"
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12">
        <h1 className="mx-lg-5 px-xl-5 font-title-page-login">{t('LANG-006')}</h1>
        <React.Fragment>
          {/* <Section title={`${t('LANG-050')} V2`} className="mx-lg-5 px-xl-5 my-4" /> */}
          {/* <div className="d-grid mx-lg-5 px-xl-5 my-4">
            <button
              className="button-v2 font-v2"
              onClick={(e) => {
                e.preventDefault();
                location.href = process.env.REACT_APP_URL_V2;
              }}>
              {t('LANG-039')}
            </button>
            <div className="d-flex flex-row align-content-center gap-1 mt-2">
              <FiAlertOctagon className="align-self-center" size={16} />
              <span className="info-v2">{t('LANG-040')}</span>
            </div>
          </div> */}
          <Form className="mx-lg-5 px-xl-5 my-4" onSubmit={handleSubmit}>
            {/* <Section title={`${t('LANG-050')} V3`} /> */}
            <Form.Group className="my-3" controlId="formBasicEmail">
              <Form.Label className="form-label-login">
                {t('LANG-021')} <span className="input-text-color"> *</span>
              </Form.Label>
              <InputGroup
                className={`${!emailValid && emailTouched ? 'border border-danger rounded' : ''}`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiUser />
                </InputGroup.Text>
                <Form.Control
                  placeholder={t('LANG-023')}
                  className="border-start-0"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => setEmailTouched(true)}
                  value={email}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      return e.currentTarget.blur();
                    }
                    return;
                  }}
                />
              </InputGroup>
              <EmailError message="Please fill your email or NIK" />
            </Form.Group>
            <Form.Group className="my-3" controlId="formBasicPassword">
              <Form.Label className="form-label-login">
                {t('LANG-022')} <span className="input-text-color"> *</span>
              </Form.Label>
              <InputGroup
                className={`${
                  !passwordValid && passwordTouched ? 'border border-danger rounded' : ''
                }`}>
                <InputGroup.Text className="bg-transparent border-end-0">
                  <BiLockAlt />
                </InputGroup.Text>
                <Form.Control
                  type={showPassword}
                  placeholder={t('LANG-022')}
                  className="border-end-0 border-start-0"
                  onChange={(e) => setPassword(e.target.value)}
                  onBlur={() => setPasswordTouched(true)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      return e.currentTarget.blur();
                    }
                    return;
                  }}
                />
                <InputGroup.Text
                  className="bg-transparent border-start-0"
                  onClick={handleShowPassword}
                  role="button">
                  {showPassword === 'password' ? <FaRegEyeSlash /> : <FaRegEye />}
                </InputGroup.Text>
              </InputGroup>
              <PasswordError message="Please fill your password correctly" />
            </Form.Group>
            <div className="d-flex flex-lg-row justify-content-between gap-2 align-items-center mb-4">
              <Form.Check
                id="check-login"
                type="checkbox"
                label={<span className="font-remember-me">Remember Me</span>}
                onChange={() => setRememberMe(!rememberMe)}
                value={rememberMe}
              />
              <Button
                as="a"
                href="/forgot-password"
                className="button-secondary font-forgot-password">
                {t('LANG-020')}
              </Button>
            </div>
            {process.env.REACT_APP_RECAPTCHA_ENABLE === 'true' && (
              <div className="mb-4 d-flex justify-content-center">
                <ReCAPTCHA
                  id="captcha"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(value) => {
                    setCaptchaResponse(value);
                    // document.body.style = 'pointer-events:none;';
                  }}
                  hl={general.language?.code || 'en'}
                />
              </div>
            )}
            <div className="d-grid text-center">
              <Button
                variant="danger"
                type="submit"
                className="text-uppercase banner-button fw-bold">
                {isLoading && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    &nbsp;
                  </>
                )}
                {t('LANG-006')}
              </Button>
              <Form.Label className="mt-2 mb-xl-3 mb-4 justify-content-center align-self-center font-sign-up">
                {t('AUTH-LOGIN-013')}
                <h6 className="mt-0 mb-0">{'\t'}</h6>
                <a
                  href="/terms-and-conditions"
                  className="text-decoration-none text-danger fw-bold">
                  {t('AUTH-LOGIN-016')}
                </a>
                &nbsp;
                {t('AUTH-LOGIN-014')}&nbsp;
                <a href="/privacy-policy" className="text-decoration-none text-danger fw-bold">
                  {t('AUTH-LOGIN-017')}
                </a>
                &nbsp;
                {t('AUTH-LOGIN-015')}
              </Form.Label>
              <Form.Label className="my-2 justify-content-center font-sign-up">
                {t('LANG-024')}&nbsp;
                <a href="/register" className="text-decoration-none text-danger fw-bold">
                  {t('LANG-007')}
                </a>
              </Form.Label>
            </div>
          </Form>
        </React.Fragment>
      </Col>
      {/* {process.env.REACT_APP_RECAPTCHA_ENABLE === 'true' && (
        <div className={`captcha-container ${loginPressed ? 'show' : 'hide'}`}>
          <ReCAPTCHA
            id="captcha"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(value) => {
              setCaptchaResponse(value);
              // login(value);
              // document.body.style = 'pointer-events:none;';
            }}
            hl={general.language?.code || 'en'}
          />
          <Button
            variant="danger"
            className="rounded-0"
            style={{
              width: 304
            }}
            onClick={() => setLoginPressed(false)}>
            {t('LANG-051')}
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default Login;
